import React             from 'react';
import { graphql }       from 'gatsby';
import Img               from 'gatsby-image';
import styled            from '@emotion/styled';
import { useMediaQuery } from '@chakra-ui/react';

import CallToAction from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import Heading      from '@interness/web-core/src/components/text/Heading/Heading';
import Separator    from '@interness/web-core/src/components/structure/Separator/Separator';
import Spacer       from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper      from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Carousel     from '@interness/web-core/src/components/media/Carousel/Carousel';
import BrandsBand   from '@interness/brands-addon/src/components/modules/BrandsBand/BrandsBand';
import HeaderImage  from '@interness/web-core/src/components/media/HeaderImage/HeaderImage';

const TopSection = styled.div`
    width: 640px;
    margin: auto;
    text-align: center;
    img {
        margin: auto;
    }
    p {
        opacity: 0.8;
        font-size: 1.4rem;
    }
`;

const fakeBrands = (logos) => {
  const brands = [];
  //eslint-disable-next-line
  logos.map((logo, i) => {
    brands.push({
      brand: {
        status: 'published',
        display_name: logo.node.name,
        logo: {
          localFile: logo.node
        },
        type: 'logo',
        slug: i
      }
    })
  });
  return brands;
};

function EventPage(props) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      <HeaderImage>
        {props.data[isMobile ? 'headerImagesMobile' : 'headerImages'].images.map((image) => (
          <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
               alt={image.file.localFile.name}/>
        ))}
      </HeaderImage>
      <Wrapper>
        <Spacer/>
        <TopSection>
          <img src="schriftzug.png" alt="Mit Liebe zum Detail"/>
          <p>Wir dekorieren Ihr Fest ganz individuell nach Ihren Wünschen,
            mit Frischblumen-Arrangements, verarbeitet im eigenen Haus.
            Wir bieten Ihnen auch den Verleih von Stuhlhussen an.</p>
        </TopSection>
        <Spacer/>
        <section>
          <Heading subtitle={'Die schönsten Momente'}>Gallerie</Heading>
          <Spacer/>
          <Carousel>
            {props.data.galleryImages.edges.map((image) => (
              <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
            ))}
          </Carousel>
        </section>
        <Spacer/>
        <section>
          <Heading>Unsere Partner</Heading>
          <Spacer/>
          <BrandsBand brands={fakeBrands(props.data.partnerLogos.edges)} linked={false} iconWidth={'200px'}/>
        </section>
        <Separator/>
        <section style={{ textAlign: 'center' }}>
          <CallToAction/>
        </section>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default EventPage;

export const query = graphql`
    query EventPageQuery {
        headerImages: directusMediaCollection(name: {eq: "event-header"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 1980, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        headerImagesMobile: directusMediaCollection(name: {eq: "event-header-mobile"}) {
            name
            images: media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        galleryImages: allFile(filter: {relativeDirectory: {eq: "event"}}, sort: {fields: name}) {
            edges {
                node {
                    ...GalleryImages
                }
            }
        }
        partnerLogos: allFile(filter: {relativeDirectory: {eq: "event/partner"}}) {
            edges {
                node {
                    name
                    publicURL
                }
            }
        }
    }
`;