import styled from '@emotion/styled';

export const BrandLinks = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  li {
    margin: 10px 10px 0 10px;
    span, a {
      line-height: normal;
      transition: opacity 0.2s ease;
      border: 1px solid black;
      opacity: ${props => props.linked ? '0.4' : '1'};
      width: ${props => props.width};
      display: block;
      &.active {
        opacity: 1;
      }
    }
    img {
      width: ${props => props.iconWidth} !important;
      margin-bottom: 0;
      padding: 8px 5px;
    }
    &:hover {
      span, a {
        opacity: 1;
      }
    }
  }
`;
