import React                       from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import PropTypes                   from 'prop-types';
import { uniqBy }                  from 'lodash';

import { Link } from '@interness/web-core/src/components/elements';
import * as S   from './styles';

const Li = (props) => {
  const brand = props.brand;
  return (
    <li>
      {props.linked
        ? <Link activeClassName='active' to={`/${brand.type.type}/${brand.slug}`} title={brand.display_name}>
            <img src={brand.logo.localFile.publicURL} alt={brand.display_name}/>
          </Link>
        : <span>
            <img src={brand.logo.localFile.publicURL} alt={brand.display_name}/>
          </span>
      }
    </li>
  )
};

function BrandsBand(props) {
  let brands;
  if (props.brands) {
    brands = props.brands;
  } else {
    brands = useStaticQuery(query).allDirectusBrands.nodes;
  }
  return (
    <nav>
      <S.BrandLinks iconWidth={props.iconWidth} linked={props.linked}>
        {/*eslint-disable-next-line*/}
        {uniqBy(brands, ({ brand }) => brand.slug).map(({ brand }) => {
          if (brand.status === 'published') {
            if (props.brandType) {
              if (brand.type.type === props.brandType) {
                return (
                  <Li brand={brand} linked={props.linked} key={`${brand.type.type}/${brand.slug}`}/>
                )
              }
            } else {
              return (
                <Li brand={brand} linked={props.linked} key={`${brand.type.type}/${brand.slug}`}/>
              )
            }
          }
        })}
      </S.BrandLinks>
    </nav>
  )
}

const query = graphql`
  query {
    allDirectusBrands {
      nodes {
        brand {
          status
          display_name
          slug
          type {
            type
          }
          logo {
            localFile {
              name
              publicURL
            }
          }
        }
      }
    }
  }
`;

BrandsBand.propTypes = {
  brandType: PropTypes.string,
  iconWidth: PropTypes.string,
  linked: PropTypes.bool,
};

BrandsBand.defaultProps = {
  iconWidth: '150px',
  linked: true,
};

export default BrandsBand;
